.payment-premium {
    min-height: 80vh !important;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    h1 {
        font-size: 20px !important;
        font-weight: bold;
    }

    .text-primary {
        color: #f56b4e !important;
    }

    .border-orange {
        border-color: #f56b4e !important;
    }

    .policy-all {
        padding-top: 0 !important;

        .policy-item {
            overflow: hidden;
        }
    }

    .no-payment {
        text-align: left;

        i {            
            display: inline-block;
            color: #68737A;
            margin-bottom: 10px;
        }
    }

    .divider {
        display: block;
        width: 120%;
        border-top: 1px solid #E5E5E5;
        margin: 10px -20px;
    }

    .section {
        background-color: #fff;
        margin: 10px -15px;
        padding: 10px;
        text-align: left;

        h2 {
            font-size: 18px;
            color: #6D757B;
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }

        i {
            color: #6D757B;
        }

        .premium-title {
            padding: 10px;
            padding-bottom: 0px;
            h1 {
                color: #6D757B;
            }
            small {
                font-size: 12.5px;
                color: black;
            }
        }

        .premium-list {
            .wrap-premium-item {
                border-bottom: 1px solid #E5E5E5;
                .premium-item {
                    border-bottom: none;
                }
            }
            .premium-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                border-bottom: 1px solid #E5E5E5;

                &:last-child {
                    border-bottom: none;
                }

                .checkbox-wrap {
                    margin-top: -2px;
                }

                .premium-amount {
                    white-space: nowrap;
                    font-weight: bold;
                    padding-left: 5px;

                    input {
                        width: 140px;
                        height: 44px;
                        text-align: right;
                        border-width: 1px;
                        border-color: #E5E5E5;
                    }
                }
            }
        }


    }
}