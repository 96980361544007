$theme-colors: (
    'primary': #ed262e,
    'secondary': #a7a7a7,
    'success': #54b123,
    'info': #029cff,
    'warning': #f1c40f,
    'danger': #e35454,
    'white': #ffffff,
    'dark': #68737a,
);

$body-bg: #f7f7f7;
$body-color: #707070;
$btn-border-radius: 4px;
$input-border-color: #e6e6e6;
$input-focus-border-color: #029cff !default;
$input-focus-box-shadow: none !default;

// Typography
$font-family-sans-serif: 'Source Sans Pro', sans-serif;

@import '~bootstrap/scss/bootstrap.scss';
