.ttb-the-teasure {
    h1 {
        font-size: 20px !important;
        font-weight: bold;
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        span {
            color: #fff;
        }

        img {
            width: 18px;
            margin-left: 5px;
        }
    }

    .btn-add {
        background-color: #e51b2d;
        border-radius: 6px;
        padding: 5px 10px;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        img {
            height: 14px;
            margin-right: 10px;
        }

        span {
            color: #fff;
        }
    }

    .section {
        background-color: #fff;
        border-radius: 13px;
        margin: 10px -15px;
        padding: 15px;
        text-align: left;
    }

    .section-complete {
        background-color: #fff;
        margin: 10px -15px;
        padding: 15px;
        text-align: center;

        img {
            width: 66px;
            margin-bottom: 20px;
        }

        .refno {
            background-color: #fff5f5;
            color: #ed1b2e;
            font-weight: bold;
            padding: 15px 10px;
            margin-bottom: 2px;
            border-radius: 9px;
        }
    }

    .claim-list-wrap {
        background-color: #fff;
        border-radius: 13px;
        padding: 15px;
        overflow: hidden;

        h2 {
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 15px;
            padding: 0 10px;
        }

        table {
            width: 110%;
            margin: 0 -15px;
            margin-bottom: 15px;

            thead {
                tr {
                    th {
                        background-color: #f5f5f5;
                        border-left: 1px solid #e5e5e5;
                        border-right: 1px solid #e5e5e5;
                        padding: 10px;
                        font-size: 14px;
                        white-space: nowrap;

                        &:first-child,
                        &:last-child {
                            background-color: #fcfcfc;
                            border: none;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border: 1px solid #e5e5e5;
                        padding: 8px;

                        &:first-child,
                        &:last-child {
                            border-left: none;
                            border-right: none;
                        }

                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    .ttb-the-teasure-form-wrap {
        background-color: #fff;
        border-radius: 15px;
        margin: 0 -15px;
        margin-bottom: 15px;
        padding: 15px;
        text-align: left;
        // overflow: hidden;

        h2 {
            font-size: 18px;
            font-weight: bold;
            color: #6d757b;
            margin-top: 10px;
        }

        ul {
            padding-left: 30px;
        }

        .example-doc {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .btn-example {
                font-size: 14px;
                font-weight: bold;
                color: #da3738;
                border: 1px solid #da3738;
                border-radius: 5px;
                padding: 0 10px;
                margin-left: 5px;
                cursor: pointer;
            }
        }

        .note-ttb {
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 10px 15px;
            margin-bottom: 15px;
            text-align: center;
            line-height: 1.2;
            color: red;
        }

        .upload-file {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            .upload-item {
                position: relative;
                flex: 1;
                width: 100%;
                cursor: pointer;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    z-index: 9;
                    opacity: 0;
                }
            }
        }

        .file-list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-top: 15px;

            .file-item {
                width: 33.33%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 15px;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 8px;

                    &.remove-item {
                        position: absolute;
                        top: -10px;
                        right: -5px;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                }

                .filename {
                    display: inline-block;
                    width: 100%;
                    max-width: 100px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .account-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border: 1px solid #efefef;
            border-radius: 10px;
            padding: 10px 15px;
            margin-bottom: 10px;

            input {
                margin-left: 0;
            }

            img {
                width: 40px;
                height: 40px;
                // border: 1px solid #979797;
                border-radius: 50%;
                object-fit: contain;
                margin-left: 30px;
                margin-right: 15px;
            }

            .account-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                span {
                    font-size: 16px;

                    &:first-child {
                        font-weight: bold;
                    }

                    &:last-child {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
        }

        .react-datepicker-wrapper {
            display: inline;
            padding: 0;
            border: 0;
        }
    }
}

.modal-bank {
    .btn-add {
        background-color: #e51b2d;
        border-radius: 6px;
        padding: 5px 10px;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        img {
            height: 14px;
            margin-right: 10px;
        }

        span {
            color: #fff;
        }
    }

    .upload-file {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        .upload-item {
            position: relative;
            flex: 1;
            width: 100%;
            cursor: pointer;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                z-index: 9;
                opacity: 0;
            }
        }
    }

    .file-list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 15px;

        .file-item {
            width: 33.33%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 15px;

            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                object-position: center;
                border-radius: 8px;

                &.remove-item {
                    position: absolute;
                    top: -10px;
                    right: -5px;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
            }

            .filename {
                display: inline-block;
                width: 100%;
                max-width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
