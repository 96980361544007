@import './_boostrap.scss';
@import './payment_premium.scss';
@import './claim.scss';
@import './ttb_the_teasure.scss';
@import './contact.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');
@import './search_partner.scss';

.card {
  margin-bottom: 15px;
  border: none;
  border-radius: 0;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);

  .card-title {
    padding: 15px;
    margin-bottom: 0;

    .img-error {
      margin: 20px 0px 50px;
      width: 100%;
      max-width: 393px;
    }
  }

  .card-body {
    background-color: #fafafa;
  }
}

.btn {
  min-width: 100px;
  svg {
    margin-right: 5px;
  }
}

.btn-secondary {
  color: #fff;
  background-color: #616161;
  border-color: #616161;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #68737a;
  background-color: #d6d7d8;
  border-color: #d6d7d8;
}

.btn-white {
  color: #3498db;
  &:hover {
    color: #4293ca;
  }
}

.btn-link {
  color: #68737a;

  &:hover {
    color: inherit;
  }
}

.btn-rounded {
  border-radius: 30px;
}

.btn-edit {
  position: absolute;
  top: -5px;
  right: 10px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  font-size: 16px;
  padding-left: 6px;
  padding-top: 1px;
  z-index: 2;
}

.contact-page {
  padding: 0;

  h1 {
    font-size: 24px;
    color: black;
    margin-bottom: 15px;
  }

  a {
    text-decoration: none;
  }

  .hotline {
    background-color: #ec1c2e;
    padding: 10px;
    color: #fff;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 20px;
      margin: 0 20px 0 10px;
    }
  }

  .info {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 #ddd;
    margin: 15px 0;
    padding: 15px;

    .info-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        width: 20px;
        margin: 0 20px 0 5px;
      }

      span {
        color: #ec1c2e;
      }
    }
  }

  .img-faq {
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
  }
}

.policy-setting-page {
  text-align: center;

  * {
    color: black;

    &:focus {
      outline: none !important;
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .change-card {
    background-color: #fff;
    padding: 15px 20px;
    margin-top: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    img {
      width: 40px;
      margin-right: 15px;
    }
  }
}

.invalid-feedback {
  color: #e35454 !important;
  padding-left: 35px;
}

.loading {
  height: 48px;
  width: 48px;
  color: rgba(90, 90, 90, 0.2) !important;
  position: relative;
  display: inline-block;
  border: 4px solid;
  border-radius: 50%;
  border-right-color: #007bff !important;
  animation: rotate 1s linear infinite;

  &.small {
    height: 24px;
    width: 24px;
    border: 2px solid;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.payment-page {
  text-align: center;
  padding-top: 15px;

  * {
    // color: black;

    &:focus {
      outline: none !important;
    }
  }

  .btn-primary {
    color: #fff;
    border-radius: 30px;
    padding: 5px 20px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .policy-checkbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    .checkbox-wrap {
      .checkbox {
        padding-left: 25px;
        .checkmark {
          top: 3px;
          border-radius: 50%;
          border-color: #8f8f8f;

          &::after {
            border-width: 0 2px 2px 0;
          }
        }

        input:checked ~ .checkmark {
          border-color: #da3739;
        }
      }
    }
  }

  .policy-list {
    margin-bottom: 20px;

    &.policy-loan {
      h1 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 5px;
        }
      }

      .no-policy {
        margin-top: 100px;

        h2 {
          font-size: 15px;
          font-weight: bold;
          color: #616161;
          line-height: 1.6;
        }

        p {
          font-size: 14px;
          color: #616161;
          line-height: 1.6;
        }

        a {
          font-size: 14px;
          color: #e51b2d;
          text-decoration: underline;
        }
      }

      .about-wrap {
        text-align: left;
        background: #ffffff;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 15px;

        h2 {
          font-size: 16px;
          font-weight: bold;
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        span {
          font-size: 15px;
          color: #616161;

          a {
            font-size: 14px;
            color: #e51b2d;
            text-decoration: underline;
          }
        }

        ol {
          margin: 0;
          margin-bottom: 10px;
          padding: 0 13px;
          font-size: 14px;
        }
      }

      .about-note {
        p {
          font-size: 13px;
          color: #000;
          margin-bottom: 5px;

          &:last-child {
            font-size: 12px;
            color: #616161;
          }
        }
      }

      .section-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          font-size: 14px;
          color: #616161;

          &:first-child {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            margin-right: 10px;
          }
        }
      }

      .policy-item {
        position: relative;
        justify-content: flex-start;
        box-shadow: unset;
        border: 2px solid #cbcbcb;
        height: 180px;

        &.no-edit {
          height: auto;

          .policy-status {
            margin-top: 10px;

            a {
              color: #fff;
              text-decoration: underline;
            }
          }
        }

        img.next {
          position: absolute;
          top: 18px;
          right: 15px;
          cursor: pointer;
          width: 16px;
          height: 16px;
        }

        p {
          font-size: 16px;
        }

        .item-50 {
          display: flex;
          width: 100%;
          margin-bottom: 5px;

          &:first-child {
            margin-top: 3px;
          }

          p {
            flex: 1;
            color: #000;

            &:last-child {
              flex: none;
              width: 150px;
            }
          }
        }
      }

      .note {
        text-align: left;
        p {
          font-size: 13px;
          color: #8f8f8f;
          margin: 0;
        }
      }
    }

    &.policy-all {
      padding-top: 45px;

      .tab-fixed {
        position: fixed;
        top: 59px;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 10px;
        box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
        z-index: 10;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow-y: auto;

        // &::-webkit-scrollbar {
        //     width: 0px;
        //     background: transparent;
        // }

        .tab {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          background-color: #fff;
          border: 1px solid #e51b2d;
          border-radius: 6px;
          padding: 4px 7px;
          margin-right: 10px;
          white-space: nowrap;

          transition: all 0.3s;

          img {
            height: 20px;
            margin-right: 4px;
          }

          span {
            font-size: 15px;
            color: #e51b2d;
          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background-color: #e51b2d;

            img {
              filter: brightness(0) invert(1);
            }

            span {
              color: #fff;
            }
          }
        }
      }

      .policy-item {
        height: auto;

        &.disabled {
          div {
            opacity: 0.5;
          }
          .status {
            opacity: 1;
          }
        }

        h2 {
          padding-left: 5px;
          padding-right: 15px;

          img {
            margin-right: 5px;
          }
        }

        p {
          font-size: 14px;
          color: #000;
          line-height: 1.7;
        }

        .item-detail {
          p {
            font-size: 15px;
            font-weight: bold;
          }
        }

        .item-tags {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
          grid-gap: 5px;
          .item-status {
            margin-bottom: 5px;
            span {
              font-size: 15px;
              color: #fff;
              padding: 4px 8px;
              border-radius: 4px;
              min-width: none;
              &.active {
                background-color: #4fcb90;
              }
              &.inactive {
                background-color: #f92c2b;
              }

              &.white {
                border: 1px solid #f92c2b;
                color: #f92c2b;
              }
            }

            .item-50 {
              display: flex;
              width: 100%;
              margin-bottom: 5px;

              &:first-child {
                margin-top: 3px;
              }

              p {
                flex: 1;
                color: #000;

                &:last-child {
                  flex: none;
                  width: 150px;
                }
              }
            }
          }
        }

        .item-epolicy {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-top: 1px solid #cbcbcb;
          margin-top: 10px;
          padding-top: 10px;

          img {
            width: 13px;
          }

          span {
            font-size: 13px;
            color: #ed1b2e;
            margin: 0 5px;
            white-space: nowrap;
          }

          small i {
            font-size: 11px;
            color: #6c747a;
            white-space: nowrap;
          }
        }
      }

      .detail-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: -75px -15px 0 -15px;
        padding: 25px 0;
        background-color: #f7f7f7;

        .icon {
          width: 35px;
        }

        h1 {
          font-size: 24px;
          margin: 15px 0 5px;
        }

        span {
          font-size: 16px;
        }
      }

      .ecare-card {
        position: relative;
        margin-top: -15px;

        img {
          width: 95%;
          max-width: 700px;
          border-radius: 6px;
        }

        .ecare-content-title {
          position: absolute;
          top: 26%;
          left: 6%;
          display: flex;
          flex-direction: row;
          margin-left: -7%;
          justify-content: center;
          align-items: center;
          width: 90%;

          * {
            // font-weight: bold;
            font-size: 9px;
            color: #616161;
          }
        }
        .ecare-content {
          position: absolute;
          top: 44%;
          left: 6%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          width: 90%;

          * {
            font-size: 8px;
            color: #616161;
          }

          .ecare-col {
            width: 49%;

            &:last-child {
              flex: 1;
            }

            // .ecare-item {
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: flex-start;
            //     align-items: flex-start;
            //     margin-bottom: 5px;

            //     span {
            //         white-space: nowrap;

            //         &:last-child {
            //             font-weight: bold;
            //             margin-left: 5px;
            //         }
            //     }
            // }
            .ecare-item-ha-type {
              display: flex;
              flex-direction: row;
              margin-left: 6%;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 5px;

              span {
                white-space: nowrap;

                &:last-child {
                  // font-weight: bold;
                  margin-left: 5px;
                }
              }
            }
            .ecare-item-hs-type {
              display: flex;
              flex-direction: row;
              // margin-left: 15%;
              // margin-left: 6%;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 10px;

              span {
                white-space: nowrap;

                &:last-child {
                  // font-weight: bold;
                  margin-left: 5px;
                }
              }
            }
            .ecare-item-pa-type {
              display: flex;
              flex-direction: row;
              // margin-left: 15%;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 15px;

              span {
                white-space: nowrap;

                &:last-child {
                  // font-weight: bold;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      .detail-withdraw {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0;

        .withdraw {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: #e51b2d;
          width: 100%;
          max-width: 360px;
          padding: 5px 10px;
          border-radius: 6px;
          margin-bottom: 10px;

          span {
            color: #fff;
          }

          img {
            width: 14px;
            filter: brightness(0) invert(1);
          }
        }
      }

      .detail-epolicy {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0;

        .download {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: #e51b2d;
          width: 100%;
          max-width: 360px;
          padding: 5px 10px;
          border-radius: 6px;
          margin-bottom: 10px;

          span {
            color: #fff;
          }

          img {
            width: 14px;
            filter: brightness(0) invert(1);
          }
        }

        small {
          i {
            color: #6c747a;
          }
        }
      }

      .detail-body {
        background-color: #fff;
        margin: 0 -15px;

        .tab-fix-top {
          position: fixed;
          top: 47px;
          left: 0;
          width: 100%;
          z-index: 10;
        }

        .detail-tabs {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          overflow-y: auto;
          padding-bottom: 10px;

          // &::-webkit-scrollbar {
          //     width: 0px;
          //     background: transparent;
          // }

          .tab {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid #e8e8e8;
            padding: 10px;
            white-space: nowrap;
            transition: all 0.3s;

            img {
              height: 20px;
              margin-right: 4px;
              filter: brightness(0);
            }

            span {
              font-size: 15px;
              color: #000;
            }

            .tab-border {
              opacity: 0;
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 5px;
              border-radius: 20px;
              background-color: #e51b2d;
              transition: all 0.3s;
            }

            &:last-child {
              margin-right: 0;
            }

            &.active {
              img {
                filter: unset;
              }

              span {
                color: #e51b2d;
              }

              .tab-border {
                opacity: 1;
              }
            }
          }
        }

        .detail-content {
          margin-top: -10px;

          .detail-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px;
            // overflow: hidden;

            p {
              font-size: 15px;
              font-weight: bold;
              color: #000;
              margin: 0;
              max-width: 50%;

              &:first-child {
                text-align: left;
              }

              &:last-child {
                font-weight: normal;
                color: #616161;
                text-align: right;
              }
            }
          }

          .detail-coverage {
            border: 1px solid #e8e8e8;
            border-radius: 12px;
            margin: 10px 10px 0;
            overflow: hidden;

            .detail-row {
              p {
                max-width: 52%;
              }

              &:first-child {
                background-color: #fff6f8;
              }
            }
          }
        }
      }
    }

    .section-title {
      background-color: #fff;
      border: 1px solid #cbcbcb;
      border-left: none;
      border-right: none;
      margin: 0 -15px;
      margin-bottom: 15px;
      padding: 5px 15px;
      text-align: left;
    }

    .policy-item {
      margin-top: 0;
      height: 190px;
    }
  }

  .btn-full {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #e51b2d;
    border-radius: 6px;
    font-size: 15px;
    white-space: nowrap;
    padding: 5px 10px;
    margin-bottom: 15px;
    cursor: pointer;

    span {
      color: #fff;
    }

    img {
      height: 13px;
      margin-left: 10px;
    }
  }

  .policy-item {
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      border-color: #de0000;
    }

    &.no-edit {
      overflow: hidden;
      cursor: no-drop;

      .policy-status {
        font-size: 12px;
        background-color: #616161;
        color: #fff;
        padding: 5px 25px;
        margin: 0 -24px 10px;
      }
    }

    &.policy-pending {
      height: auto !important;
    }

    h2 {
      font-weight: bold;
      margin: 0;
      margin-bottom: 5px;

      span {
        color: #de0000;
      }
    }

    p {
      font-size: 14px;
      color: #7b7b7b;
      margin: 0;
    }
  }

  .payment-complete-title {
    font-size: 20px;
    font-weight: bold;
  }
}

.toast {
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  background-color: #54b123;
  border: none;

  .toast-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    color: #fff;
    padding: 10px;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}

.payment-method-page {
  text-align: center;
  padding-top: 15px;

  * {
    color: black;

    &:focus {
      outline: none !important;
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .method-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .change-card {
      background-color: #fff;
      padding: 15px 20px;
      margin-right: 15px;
      border-radius: 8px;
      border: 2px solid transparent;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;

      &.active {
        border-color: #de0000;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-bottom: 15px;
      }
    }
  }
}

.checkbox-wrap {
  color: #4f5153;
  margin-top: 15px;

  u {
    color: #da3739;
  }

  .checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: #da3739;
      }

      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 20px;
      width: 20px;
      border: 2px solid #da3739;
      border-radius: 4px;

      &:after {
        content: '';
        position: absolute;
        display: none;
      }

      &:after {
        left: 5px;
        top: 0px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.flex {
  display: flex;
  width: 100%;

  &.flex-row {
    flex-direction: row;
  }

  &.flex-col {
    flex-direction: column;
  }

  &.space-between {
    justify-content: space-between;
    align-items: center;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.start {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

// .tooltip {
//     z-index: 9;
// }

// .tooltip.show {
//     opacity: 1;
// }

.tooltip-inner {
  // text-align: left;
  // padding: 10px;
  // color: #222;
  // background-color: #fff;
  // box-shadow: 1px 1px 5px 1px #ddd;
  // border: 1px solid #222 !important;
  font-size: 10px;
}

// .tooltip .arrow {
//     display: none;
// }

.text-grey {
  color: #616161 !important;
}

.text-black {
  color: #222222 !important;
}

.nowrap {
  max-width: none !important;
  white-space: nowrap;
}

.go-to-bottom,
.go-to-top {
  position: fixed;
  bottom: 75px;
  right: 10px;
  width: 50px;
  height: 50px;
  object-fit: contain;
  cursor: pointer;
  z-index: 9;

  &.bt0 {
    bottom: 30px;
  }
}

.go-to-top {
  transform: rotate(180deg);
}

.btn-fix-top {
  position: fixed;
  top: 47px;
  left: 0;
  width: 100%;
  background: #f7f7f7;
  padding: 10px 15px;
  z-index: 10;

  .btn-full {
    margin-bottom: 0;
  }
}

.payment-setting-page {
  text-align: center;
  background-color: white;
  padding-top: 15px;

  &.payment-credit {
    background-color: transparent;
    text-align: left;
    padding: 15px;

    .btn {
      border-radius: 30px !important;
      padding: 5px 15px;

      &.btn-primary {
        color: #fff;
      }
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
      opacity: 0.4;
      background-color: #ed262e;
      border-color: #ed262e;
    }

    .btn-outline {
      background-color: transparent;
      border: 1px solid #7b7b7b;
      color: #505050;
    }

    .consent-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .btn {
        margin-top: 20px;
        width: 200px;
      }
    }

    &.payment-form {
      padding: 0;
      margin: 0 -15px;

      .header,
      .footer {
        background: #fff;
        padding: 30px 15px;
        text-align: center;

        h2 {
          margin-bottom: 25px;
        }

        .card-type {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .card-item {
            background-color: #fff;
            padding: 6px 20px;
            margin: 0 8px;
            border-radius: 6px;
            border: 2px solid transparent;
            box-shadow: 0 0 5px 0 #ddd;
            transition: all 0.1s;
            cursor: pointer;

            &.active {
              border-color: #de0000;
            }

            img {
              width: 50px;
              height: 30px;
              object-fit: contain;
            }
          }
        }

        .card-note {
          display: inline-block;
          background-color: #ffe5e5;
          color: #de0000;
          font-size: 12px;
          padding: 10px 25px;
          margin-top: 25px;
          margin-bottom: 0;
          border-radius: 6px;
        }

        .upload-file {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;

          .upload-item {
            position: relative;
            flex: 1;
            width: 100%;
            background-color: #fff;
            padding: 15px;
            margin: 0 8px;
            border-radius: 6px;
            box-shadow: 0 0 5px 0 #ddd;
            cursor: pointer;

            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              z-index: 9;
              opacity: 0;
            }

            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
              margin-bottom: 10px;
            }

            .process-verified {
              padding: 0;
              margin-bottom: 10px;

              .checkmark {
                margin: 0;
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }

      .body {
        background-color: #f8f8f8;
        padding: 30px 15px;

        .select-center {
          text-align-last: center;
        }

        .checkbox {
          &.disabled {
            .checkmark {
              top: 2px;
            }
          }
        }
      }
    }

    .process-verified {
      text-align: center;

      * {
        color: #505050;
      }

      h1 {
        line-height: 1.5;
      }

      .text-success {
        font-size: 24px;
        color: #53b133;
      }

      .btn-under {
        font-size: 20px;
        text-decoration: underline;
        color: #e51b2d;
        cursor: pointer;
      }

      p {
        font-size: 20px;
        margin: 0;
        color: #616161;
      }

      .summary {
        width: 100%;
        margin: 15px 0;

        .summary-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 15px 10px;
          border-bottom: 1px solid #e0e0e0;

          img {
            width: 70px;
            background: #fff;
            padding: 10px;
            border-radius: 4px;
            box-shadow: 0 0 5px 0 #ddd;
          }
        }
      }
    }

    .summary-wrap {
      text-align: center;

      * {
        color: #505050;
      }

      h1 {
        line-height: 1.5;
      }

      p {
        margin: 0;
      }

      .summary {
        width: 100%;
        margin: 15px 0;

        .summary-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 15px 10px;
          border-bottom: 1px solid #e0e0e0;

          img {
            width: 70px;
            background: #fff;
            padding: 10px;
            border-radius: 4px;
            box-shadow: 0 0 5px 0 #ddd;
          }
        }
      }
    }
  }

  &.policy-loan {
    .bg-white {
      background: #fff;
      padding: 20px 15px;
      margin: 0 -20px;

      ol {
        padding-left: 15px;
        li {
          color: #616161;
        }
      }
    }

    .checkbox-wrap .checkbox .checkmark {
      border-color: #8f8f8f;
    }

    .checkbox-wrap .checkbox input:checked ~ .checkmark {
      border-color: #da3739;
    }

    .text-primary {
      color: #f56b4e !important;
    }

    .border-orange {
      border-color: #f56b4e !important;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }

    .btn {
      padding: 8px 15px;
    }

    .navigation {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 10px;
      margin-bottom: 20px;

      .line {
        position: absolute;
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 235px;
        height: 1px;
        background-color: #a3a3a3;
        z-index: 1;
      }

      .nav-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        width: 83px;
        z-index: 2;

        &:nth-child(2) {
          margin: 0 45px;
        }

        &.active {
          .number {
            color: #e51b2d;
            border-color: #e51b2d;
          }

          .label {
            color: #e51b2d;
          }
        }

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: 500;
          color: #a3a3a3;
          width: 35px;
          height: 35px;
          border: 2px solid #a3a3a3;
          border-radius: 50%;
        }

        .label {
          font-size: 15px;
          font-weight: 700;
          color: #a3a3a3;
        }
      }
    }

    .form-cal {
      background: #fff;
      padding: 15px;
      margin-top: 10px;

      p {
        margin: 0;
        max-width: 200px;
      }

      input {
        width: 120px;
        height: 44px;
        text-align: right;
        border-width: 2px;
        border-color: #a3a3a3;
      }

      .flex {
        margin-bottom: 10px;
        p {
          &:last-child {
            padding-right: 12px;
            color: #616161;
          }
        }

        &.flex-start {
          align-items: flex-start;
        }
      }

      .total {
        border-top: 2px solid #a3a3a3;
        border-bottom: 2px solid #a3a3a3;
        padding: 10px 0;
        margin: 15px 0;
      }
    }

    .loan-form {
      background-color: #fff !important;
      margin-top: 10px;

      &.upload {
        p,
        small {
          color: #616161;
        }

        .upload-file {
          margin-top: 30px;

          .upload-item {
            flex: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: unset;
            border: 2px solid #cbcbcb;
            border-radius: 6px;
            width: 170px;
            height: 150px;
          }
        }
      }

      .radio {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 30px 0 10px;

        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default radio button */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom radio button */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border-radius: 50%;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #e51b2d;
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
          top: 2px;
          left: 2px;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }
    }

    .form-summary {
      background: #fff;
      padding: 15px;
      margin-top: 10px;

      p {
        margin-bottom: 5px;
      }
    }

    .btn-fix-btn {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #f7f7f7;
      padding: 10px 15px;
      box-shadow: 1px 1px 5px 1px #ddd;
    }
  }

  &.policy-ttb-the-tersure-summary {
    .bg-white {
      background: #fff;
      padding: 20px 15px;
      margin: 0 -20px;

      ol {
        padding-left: 15px;
        li {
          color: #616161;
        }
      }
    }

    .checkbox-wrap .checkbox .checkmark {
      border-color: #8f8f8f;
    }

    .checkbox-wrap .checkbox input:checked ~ .checkmark {
      border-color: #da3739;
    }

    .text-primary {
      color: #f56b4e !important;
    }

    .border-orange {
      border-color: #f56b4e !important;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }

    .btn {
      padding: 8px 15px;
    }

    .navigation {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 10px;
      margin-bottom: 20px;

      .line {
        position: absolute;
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 235px;
        height: 1px;
        background-color: #a3a3a3;
        z-index: 1;
      }

      .nav-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        width: 83px;
        z-index: 2;

        &:nth-child(2) {
          margin: 0 45px;
        }

        &.active {
          .number {
            color: #e51b2d;
            border-color: #e51b2d;
          }

          .label {
            color: #e51b2d;
          }
        }

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: 500;
          color: #a3a3a3;
          width: 35px;
          height: 35px;
          border: 2px solid #a3a3a3;
          border-radius: 50%;
        }

        .label {
          font-size: 15px;
          font-weight: 700;
          color: #a3a3a3;
        }
      }
    }

    .form-cal {
      background: #fff;
      padding: 15px;
      margin-top: 10px;

      p {
        margin: 0;
        max-width: 200px;
      }

      input {
        width: 120px;
        height: 44px;
        text-align: right;
        border-width: 2px;
        border-color: #a3a3a3;
      }

      .flex {
        margin-bottom: 10px;
        p {
          &:last-child {
            padding-right: 12px;
            color: #616161;
          }
        }

        &.flex-start {
          align-items: flex-start;
        }
      }

      .total {
        border-top: 2px solid #a3a3a3;
        border-bottom: 2px solid #a3a3a3;
        padding: 10px 0;
        margin: 15px 0;
      }
    }

    .loan-form {
      background-color: #fff !important;
      margin-top: 10px;

      &.upload {
        p,
        small {
          color: #616161;
        }

        .upload-file {
          margin-top: 30px;

          .upload-item {
            flex: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: unset;
            border: 2px solid #cbcbcb;
            border-radius: 6px;
            width: 170px;
            height: 150px;
          }
        }
      }

      .radio {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 30px 0 10px;

        .container {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default radio button */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom radio button */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border-radius: 50%;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #e51b2d;
        }

        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the indicator (dot/circle) when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the indicator (dot/circle) */
        .container .checkmark:after {
          top: 2px;
          left: 2px;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }
    }

    .form-summary {
      background: #fff;
      padding: 15px;
      margin-top: 10px;

      p {
        margin-bottom: 5px;
      }
    }

    .btn-fix-btn {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #f7f7f7;
      padding: 10px 15px;
      box-shadow: 1px 1px 5px 1px #ddd;
    }
  }

  * {
    color: black;

    &:focus {
      outline: none !important;
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px 0 40px;
    overflow: hidden;

    .tab-item-wrap {
      flex: 1;
      width: 100%;
      padding: 10px 10px 7px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        border-color: #de0000;
      }

      &:last-child {
        .tab-item {
          border-right: none;
        }
      }

      .tab-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #e2e2e2;
        margin: 0 -10px;

        img {
          height: 75px;
          margin-bottom: 10px;
        }

        span {
          font-size: 14px;
          color: #707070;
        }
      }
    }
  }

  .slick-slider {
    margin-top: 20px;

    .bank-list {
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding: 10px 0;

      img {
        width: 100%;
        max-width: 100px;
        margin: 0 5px;
        border: 4px solid #fff;
        border-radius: 50%;
        opacity: 0.5;
        cursor: pointer;

        &.active {
          opacity: 1;
          box-shadow: 0 0 5px 0 #ddd;
        }
      }
    }
  }

  .tabs-content {
    padding: 0 10px;
    min-height: 500px;

    .tab-item {
      display: none;

      &.active {
        display: block;
      }

      h2 {
        padding: 0;
      }

      .bank-link {
        display: inline-block;

        .btn-bank {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding-right: 10px;
          width: 250px;
          margin-bottom: 40px;
          border-radius: 30px;
          text-decoration: none;

          img {
            width: 50px;
            margin-right: 10px;
          }

          &.bank-scb {
            background-color: #4b2d85;
          }

          &.bank-bay {
            background-color: #726060;
          }

          &.bank-ktb {
            background-color: #009bd9;
          }

          &.bank-ktc {
            background-color: #000;
          }

          &.bank-bbl {
            background-color: #2e3192;
          }

          &.bank-kbank {
            background-color: #37a34b;
          }

          &.bank-tmb {
            background-color: #0177c1;
          }

          &.bank-tbank {
            background-color: #fd4f00;
          }
        }
      }

      .order-detail {
        padding: 10px;
        margin-top: 70px;
        margin-bottom: 35px;

        * {
          font-size: 14px;
        }

        .order-header {
          font-size: 16px;
          color: #fff;
          background-color: #de0000;
          border: 1px solid #de0000;
          border-radius: 4px 4px 0 0;
          padding: 10px;
        }

        .order-subheader {
          border: 1px solid #d8d8d8;
          border-top: none;
          padding: 10px;
        }

        .order-body {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .body-item {
            flex: 1;
            width: 100%;
            border-left: 1px solid #d8d8d8;
            border-right: 1px solid #d8d8d8;

            &:last-child {
              border-left: none;
            }

            div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 10px;
              white-space: nowrap;
            }
          }
        }

        .order-footer {
          font-size: 12px;
          color: #de0000;
          background-color: #fdefef;
          border: 1px solid #de0000;
          border-radius: 0 0 4px 4px;
          padding: 10px;
        }
      }

      .step-detail {
        margin: 10px 0;

        .step-row {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin: 15px 0;

          .step-item {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              max-width: 100px;
              margin: 25px 0 15px;
            }

            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .slick-dots {
    bottom: -40px;

    li {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      box-shadow: 0 0 5px 0 #ddd;

      &.slick-active {
        button:before {
          color: #e80707;
        }
      }

      button:before {
        font-size: 15px;
        line-height: 20px;
        width: 19px;
        height: 19px;
        opacity: 1;
        color: #ccc;
      }
    }
  }
}

.text-primary {
  color: #f56b4e !important;
}

.border-orange {
  border-color: #f56b4e !important;
}

fieldset.form-group {
  display: flex;
  justify-content: flex-start;
  align-items: normal;

  .form-check {
    width: 50%;
  }
}

input[type='radio'] {
  accent-color: #ed1b2e;
  width: 18px;
  height: 18px;
}

select {
  width: 268px;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  // background: url('https://www.img.in.th/images/dec8bdf96e4bf2b5da22353150ec1c0a.png') no-repeat right #fff;
  // background-position: right 10px center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.radio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px;

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #e51b2d;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 2px solid #fff;
  }
}

.text-red {
  color: #ed1b2e;
}

.text-yellow {
  color: #ec7f00;
}

.text-green {
  color: #4fcb90;
}

.text-grey {
  color: #6d757b;
}

.modal-backdrop {
  background-color: #68737a;

  &.show {
    opacity: 0.9;
  }
}

.modal-full {
  max-width: 100%;
  height: 100%;
  margin: 0;

  .modal-content {
    background-color: #f7f8f8;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: none;

    h5 {
      line-height: 1.5;
      margin-bottom: 20px;
    }

    .card {
      margin-bottom: 0;
      border-radius: 0;
      border-bottom: 1px solid #eeeeee;
      box-shadow: unset;
    }
  }
}

.modal-nested {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .modal-content {
    // background-color: #f7f8f8;
    border: none;

    .modal-body {
      padding-bottom: 0;
      overflow: hidden;
    }

    h4 {
      line-height: 1.5;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .btn {
      display: block;
      width: 100%;
    }
  }
}

.modal-popup {
  color: #000;

  h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  .btn {
    color: #fff;
    border-radius: 30px;
    padding: 5px 20px;
    margin: 0px auto;
    max-width: 120px;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    padding-top: 20px;
    border-top: 1px solid #e8e8e8;
  }
}

.modal-transparent {
  .modal-content {
    background-color: transparent !important;
  }
}
.modal-search {
  max-width: 100%;
  height: 100%;
  margin: 0;

  .modal-content {
    background-color: #f7f7f7;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: none;

    .modal-body {
      padding: 0;
      padding-top: 59px;

      .search-header {
        width: 100%;
        position: fixed;
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        top: 0;
        background-color: #fff;
        text-align: center;
        padding: 10px 15px;
        box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
        transition: top 0.4s;
        z-index: 10;

        img {
          width: 100%;
          max-width: 145px;
        }

        span {
          width: 25px;

          img {
            cursor: pointer;
          }
        }
      }

      .search-box {
        background-color: #fff;
        padding: 10px 15px;

        .search-wrap {
          position: relative;

          img {
            position: absolute;
            top: 9px;

            &:first-child {
              width: 20px;
              left: 10px;
            }

            &:last-child {
              width: 15px;
              top: 12px;
              right: 10px;
            }
          }

          input {
            padding: 0 37px;
          }
        }
      }

      .search-result {
        h2 {
          font-size: 24px;
          font-weight: bold;
          color: #000;
          padding: 15px;
          margin: 0;
        }

        .result-item {
          background-color: #fff;
          padding: 10px 15px;
          margin-bottom: 2px;
        }
      }
    }
  }
}

.modal-bank {
  max-width: 100%;
  height: 100%;
  margin: 0;

  .modal-content {
    background-color: #f7f7f7;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: none;

    .modal-body {
      padding: 0;
      padding-top: 59px;

      .btn-primary {
        color: #fff;
        border-radius: 30px;
        padding: 5px 20px;
      }

      .bank-header {
        width: 100%;
        position: fixed;
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        top: 0;
        background-color: #fff;
        text-align: center;
        padding: 10px 15px;
        box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
        transition: top 0.4s;
        z-index: 10;

        img {
          width: 100%;
          max-width: 145px;
        }

        span {
          width: 25px;

          img {
            cursor: pointer;
          }
        }
      }

      h1 {
        font-size: 20px !important;
        font-weight: bold;
        color: #000;
        margin: 20px 0;
        text-align: center;
      }

      .bank-box {
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 15px;
        padding: 15px;
        text-align: left;
        overflow: hidden;

        h2 {
          font-size: 18px;
          font-weight: bold;
          color: #6d757b;
          margin-top: 10px;
        }
      }
    }
  }
}

.process-verified {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 70px;

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #54b123;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 30px auto;
    box-shadow: inset 0px 0px 0px #54b123;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #54b123;
    }
  }
}

.process-error {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 70px;

  img {
    min-height: 200px;
    margin-bottom: 40px;
    animation: scaling 0.5s ease-in-out 0.4s forwards;
  }

  p {
    margin-bottom: 40px;
  }

  div {
    width: 100%;

    .btn-primary {
      border-radius: 30px;
    }
  }

  @keyframes scaling {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(0.1);
    }
    60% {
      transform: scale(1.1);
    }
    80% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
}
// mobile
@media screen and (max-width: 575px) {
  .modal-full {
    .modal-content {
      .btn {
        display: block;
        width: 100%;
      }
    }
  }

  .payment-setting-page {
    margin: 0 -10px;

    .tabs {
      .tab-item-wrap {
        .tab-item {
          img {
            height: 65px;
          }
        }
      }
    }

    .tabs-content {
      .tab-item {
        .step-detail {
          .step-row {
            flex-direction: column;
          }
        }
      }
    }
  }

  .privilege-page {
    font-family: 'Noto Sans Thai', sans-serif;
    width: 100%;
    padding-left: 0px;
    padding-top: 9px;

    * {
      color: black;

      &:focus {
        outline: none !important;
      }
    }

    .first-card-title {
      font-size: 28px;
      font-weight: 700;
      margin: 0px 26px 16px 26px !important;
    }
    .first-card-title-content {
      margin: 0px 26px !important;
      line-height: 24px;
    }

    .sub-title-content {
      margin: 8px 26px !important;
      font-size: 11px !important;
    }

    .privilege-card-primary {
      position: relative;
      width: 100%;
      height: 600px;
      // border: 2px solid #73ad21;
      .pr-bg {
        position: absolute;
        width: 252px;
        height: 436px;
      }
      .privilege-card-content {
        align-items: center;
        margin: 0px 22px 0px 22px;
        .img-content-main {
          // background-color: none;
          position: relative;
          // border-radius: 8px;
          // border: 2px solid #73ad21;
          width: 100%;
          height: 477px;
          .tab-box {
            // border-radius: 8px;
            // border: 2px solid #73ad21;
            margin-top: 24px;
            .tab-header {
              background: #1b365d;
              color: #ffffff;
              height: 60px;
              font-family: 'Noto Sans Thai', sans-serif;
              font-weight: 700;
              font-size: 18px;
              line-height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px 8px 0px 0px;
            }
            .tab-card-content-img {
              img {
                width: 100%;
                // height: 234px;
              }
              .btn-readmore {
                background: white;
                // border: 2px solid #73ad21;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0px 0px 8px 8px;
                img {
                  margin: 0px 0px 32px 0px;
                  width: 268px;
                  height: 52px;
                }
              }
            }
          }
        }
        .card-btn-link-main {
          background-color: rgb(255, 255, 255);
          height: 100px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .button:not(:disabled),
      [type='button']:not(:disabled),
      [type='reset']:not(:disabled),
      [type='submit']:not(:disabled) {
        font-family: 'Noto Sans Thai', sans-serif;
        cursor: pointer;
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        opacity: 0.4;
        padding: 0px;
      }

      .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
        font-family: 'Noto Sans Thai', sans-serif;
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        opacity: 1;
        padding: 0px;
      }

      .css-1gsv261 {
        border-bottom: none;
      }

      .css-19kzrtu {
        padding: 0px;
      }
    }

    .card-other-2 {
      position: relative;
      width: 100%;
      height: 580px;
      // border: 2px solid #73ad21;
      .sub-title {
        font-size: 24px;
        font-weight: 700;
        margin: 60px 26px 7px 26px !important;
      }
      .sub-title-box {
        position: relative;
        width: 100%;
        height: 410px;
        // border: 2px solid #73ad21;
        // margin-bottom: 100px;
        .sub-title-box-detail {
          position: absolute;
          top: 0;
          // border: 1px solid #73ad21;
          z-index: 1;
          // padding-top: 7%;
          margin: 13px 26px 13px 26px !important;
        }
        .bg-shadow-other {
          position: absolute;
          right: 0;
          // width: 80%;
          // height: 80%;
          // border: 3px solid #73ad21;

          width: 252px;
          height: 339px;
          z-index: -1;
        }
        .bg-shadow-content {
          // position: absolute;
          // left: 0;
          // top: 28%;
          width: 100%;
          // border: 2px solid #73ad21;
          margin-top: 20px;
        }
      }

      .other-link {
        p {
          text-decoration: none;
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 16px;
        }
      }
    }

    .card-other-3 {
      position: relative;
      // width: 100%;
      height: 750px;
      // border: 2px solid #73ad21;
      margin-bottom: 0px;
      .bg-shadow {
        position: absolute;
        bottom: 5%;
      }

      .content-img {
        position: absolute;
        // border: 2px solid #73ad21;
        img {
          width: 100%;
        }
      }

      .content-container {
        position: absolute;
        bottom: 12%;
        // border: 1px solid #73ad21;
        width: 93%;
        height: 269px;
        background: #ffffff;
        box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
        border-radius: 0px 8px 8px 0px;
      }
    }
    .check-health {
      position: absolute;
      bottom: -2%;
      // border: 1px solid #73ad21;
      width: 100%;
      height: 70px;
      // box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
      left: 50%;
      transform: translate(-50%);

      .check-health-btn {
        position: absolute;
        padding: 0px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .link-with-arrow-red {
      position: absolute;
      bottom: -9%;
      // border: 1px solid #73ad21;
      width: 80%;
      height: 45px;
      left: 54%;
      transform: translate(-50%);
      .arrow-svg {
        display: flex;
        justify-content: end;
      }
    }
  }
}

@media screen and (max-width: 412px) {
  .payment-page {
    .policy-list {
      &.policy-all {
        .policy-item {
          h2 {
            font-size: 16px;

            // img {
            //     width: 12px;
            // }
          }

          p {
            font-size: 12px;
          }

          .item-detail p {
            font-size: 14px;
          }

          .item-status span {
            font-size: 14px;
          }

          .item-epolicy {
            img {
              width: 14px;
            }

            span {
              font-size: 13px;
            }

            small i {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  .privilege-page {
    font-family: 'Noto Sans Thai', sans-serif;
    width: 100%;
    padding-left: 0px;
    padding-top: 9px;

    * {
      color: black;

      &:focus {
        outline: none !important;
      }
    }

    .first-card-title {
      font-size: 28px;
      font-weight: 700;
      margin: 0px 26px 16px 26px !important;
    }
    .first-card-title-content {
      margin: 0px 26px !important;
      line-height: 24px;
    }

    .sub-title-content {
      margin: 8px 26px !important;
      font-size: 11px !important;
    }

    .privilege-card-primary {
      position: relative;
      width: 100%;
      height: 600px;
      // border: 2px solid #73ad21;
      .pr-bg {
        position: absolute;
        width: 252px;
        height: 436px;
      }
      .privilege-card-content {
        align-items: center;
        margin: 0px 22px 0px 22px;
        .img-content-main {
          // background-color: none;
          position: relative;
          // border-radius: 8px;
          // border: 2px solid #73ad21;
          width: 100%;
          height: 477px;
          .tab-box {
            // border-radius: 8px;
            // border: 2px solid #73ad21;
            margin-top: 24px;
            .tab-header {
              background: #1b365d;
              color: #ffffff;
              height: 60px;
              font-family: 'Noto Sans Thai', sans-serif;
              font-weight: 700;
              font-size: 18px;
              line-height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px 8px 0px 0px;
            }
            .tab-card-content-img {
              img {
                width: 100%;
                // height: 234px;
              }
              .btn-readmore {
                background: white;
                // border: 2px solid #73ad21;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0px 0px 8px 8px;
                img {
                  margin: 0px 0px 32px 0px;
                  width: 268px;
                  height: 52px;
                }
              }
            }
          }
        }
        .card-btn-link-main {
          background-color: rgb(255, 255, 255);
          height: 100px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .button:not(:disabled),
      [type='button']:not(:disabled),
      [type='reset']:not(:disabled),
      [type='submit']:not(:disabled) {
        font-family: 'Noto Sans Thai', sans-serif;
        cursor: pointer;
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        opacity: 0.4;
        padding: 0px;
      }

      .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
        font-family: 'Noto Sans Thai', sans-serif;
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        opacity: 1;
        padding: 0px;
      }

      .css-1gsv261 {
        border-bottom: none;
      }

      .css-19kzrtu {
        padding: 0px;
      }
    }

    .card-other-2 {
      position: relative;
      width: 100%;
      height: 580px;
      // border: 2px solid #73ad21;
      .sub-title {
        font-size: 24px;
        font-weight: 700;
        margin: 60px 26px 7px 26px !important;
      }
      .sub-title-box {
        position: relative;
        width: 100%;
        height: 410px;
        // border: 2px solid #73ad21;
        // margin-bottom: 100px;
        .sub-title-box-detail {
          position: absolute;
          top: 0;
          // border: 1px solid #73ad21;
          z-index: 1;
          // padding-top: 7%;
          margin: 13px 26px 13px 26px !important;
        }
        .bg-shadow-other {
          position: absolute;
          right: 0;
          // width: 80%;
          // height: 80%;
          // border: 3px solid #73ad21;

          width: 252px;
          height: 339px;
          z-index: -1;
        }
        .bg-shadow-content {
          // position: absolute;
          // left: 0;
          // top: 28%;
          width: 100%;
          // border: 2px solid #73ad21;
          margin-top: 20px;
        }
      }

      .other-link {
        p {
          text-decoration: none;
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 16px;
        }
      }
    }

    .card-other-3 {
      position: relative;
      // width: 100%;
      height: 750px;
      // border: 2px solid #73ad21;
      margin-bottom: 0px;
      .bg-shadow {
        position: absolute;
        bottom: 5%;
      }

      .content-img {
        position: absolute;
        // border: 2px solid #73ad21;
        img {
          width: 100%;
        }
      }

      .content-container {
        position: absolute;
        bottom: 12%;
        // border: 1px solid #73ad21;
        width: 93%;
        height: 269px;
        background: #ffffff;
        box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
        border-radius: 0px 8px 8px 0px;
      }
    }
    .check-health {
      position: absolute;
      bottom: -2%;
      // border: 1px solid #73ad21;
      width: 100%;
      height: 70px;
      // box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
      left: 50%;
      transform: translate(-50%);

      .check-health-btn {
        position: absolute;
        padding: 0px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .link-with-arrow-red {
      position: absolute;
      bottom: -9%;
      // border: 1px solid #73ad21;
      width: 80%;
      height: 45px;
      left: 54%;
      transform: translate(-50%);
      .arrow-svg {
        display: flex;
        justify-content: end;
      }
    }
  }
}
// end mobile

@media screen and (max-width: 375px) {
  .payment-page {
    .policy-list {
      &.policy-all {
        .policy-item {
          h2 {
            font-size: 16px;

            //img {
            //    width: 12px;
            //}
          }

          p {
            font-size: 12px;
          }

          .item-detail p {
            font-size: 14px;
          }

          .item-status span {
            font-size: 14px;
          }

          .item-epolicy {
            img {
              width: 14px;
            }

            span {
              font-size: 13px;
            }

            small i {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .payment-page {
    .policy-list {
      &.policy-all {
        .policy-item {
          h2 {
            font-size: 14px;

            //img {
            //    width: 10px;
            //}
          }

          p {
            font-size: 10px;
          }

          .item-detail p {
            font-size: 12px;
          }

          .item-status span {
            font-size: 12px;
          }

          .item-epolicy {
            img {
              width: 12px;
            }

            span {
              font-size: 13px;
            }

            small i {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .payment-page {
    .policy-list {
      &.policy-all {
        .policy-item {
          h2 {
            font-size: 14px;

            //img {
            //    width: 10px;
            //}
          }

          p {
            font-size: 10px;
          }

          .item-detail p {
            font-size: 12px;
          }

          .item-status span {
            font-size: 12px;
          }

          .item-epolicy {
            img {
              width: 12px;
            }

            span {
              font-size: 10px;
            }

            small i {
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}

//ipad
@media screen and (min-width: 700px) {
  .payment-page .policy-list.policy-all .ecare-card {
    .ecare-content-title {
      position: absolute;
      top: 25%;
      left: 6%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 90%;

      * {
        font-weight: bold;
        font-size: 14px;
        color: #616161;
      }
    }
    .ecare-content {
      * {
        font-size: 14px;
        color: #616161;
      }

      .ecare-col {
        .ecare-item {
          margin-bottom: 10px;

          span {
            &:last-child {
              margin-left: 7px;
            }
          }
        }

        .ecare-item-ha-type {
          margin-bottom: 10px;

          span {
            &:last-child {
              margin-left: 7px;
            }
          }
        }
        .ecare-item-hs-type {
          // margin-left: 50px;
          margin-bottom: 20px;

          span {
            &:last-child {
              margin-left: 7px;
            }
          }
        }

        .ecare-item-pa-type {
          // margin-left: 50px;
          margin-bottom: 20px;

          span {
            &:last-child {
              margin-left: 7px;
            }
          }
        }
      }
    }
  }

  .privilege-page {
    font-family: 'Noto Sans Thai', sans-serif;
    width: 100%;
    padding-left: 0px;
    padding-top: 9px;

    * {
      color: black;

      &:focus {
        outline: none !important;
      }
    }

    .first-card-title {
      font-size: 28px;
      font-weight: 700;
      margin: 0px 26px 16px 26px !important;
    }
    .first-card-title-content {
      margin: 0px 26px !important;
      line-height: 24px;
    }

    .sub-title-content {
      margin: 8px 26px !important;
      font-size: 11px !important;
    }

    .privilege-card-primary {
      position: relative;
      width: 100%;
      height: 850px;
      // border: 2px solid #73ad21;
      .pr-bg {
        position: absolute;
        width: 252px;
        height: 436px;
      }
      .privilege-card-content {
        align-items: center;
        margin: 0px 22px 0px 22px;
        .img-content-main {
          // background-color: none;
          position: relative;
          // border-radius: 8px;
          // border: 2px solid #73ad21;
          width: 100%;
          height: 477px;
          .tab-box {
            // border-radius: 8px;
            // border: 2px solid #73ad21;
            margin-top: 24px;
            .tab-header {
              background: #1b365d;
              color: #ffffff;
              height: 60px;
              font-family: 'Noto Sans Thai', sans-serif;
              font-weight: 700;
              font-size: 18px;
              line-height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px 8px 0px 0px;
            }
            .tab-card-content-img {
              img {
                width: 100%;
                // height: 234px;
              }
              .btn-readmore {
                background: white;
                // border: 2px solid #73ad21;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0px 0px 8px 8px;
                img {
                  margin: 0px 0px 32px 0px;
                  width: 268px;
                  height: 52px;
                }
              }
            }
          }
        }
        .card-btn-link-main {
          background-color: rgb(255, 255, 255);
          height: 100px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .button:not(:disabled),
      [type='button']:not(:disabled),
      [type='reset']:not(:disabled),
      [type='submit']:not(:disabled) {
        font-family: 'Noto Sans Thai', sans-serif;
        cursor: pointer;
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        opacity: 0.4;
        padding: 0px;
      }

      .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
        font-family: 'Noto Sans Thai', sans-serif;
        color: #000000;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        opacity: 1;
        padding: 0px;
      }

      .css-1gsv261 {
        border-bottom: none;
      }

      .css-19kzrtu {
        padding: 0px;
      }
    }

    .card-other-2 {
      position: relative;
      width: 100%;
      height: 900px;
      // border: 2px solid #73ad21;
      .sub-title {
        font-size: 24px;
        font-weight: 700;
        margin: 60px 26px 7px 26px !important;
      }
      .sub-title-box {
        position: relative;
        width: 100%;
        height: 410px;
        // border: 2px solid #73ad21;
        // margin-bottom: 100px;
        .sub-title-box-detail {
          position: absolute;
          top: 0;
          // border: 1px solid #73ad21;
          z-index: 1;
          // padding-top: 7%;
          margin: 13px 26px 13px 26px !important;
        }
        .bg-shadow-other {
          position: absolute;
          right: 0;
          // width: 80%;
          // height: 80%;
          // border: 3px solid #73ad21;

          width: 252px;
          height: 339px;
          z-index: -1;
        }
        .bg-shadow-content {
          // position: absolute;
          // left: 0;
          // top: 28%;
          width: 100%;
          // border: 2px solid #73ad21;
          margin-top: 20px;
        }
      }

      .other-link {
        p {
          text-decoration: none;
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 16px;
        }
      }
    }

    .card-other-3 {
      position: relative;
      // width: 100%;
      height: 1080px;
      // border: 2px solid #73ad21;
      margin-bottom: 0px;
      .bg-shadow {
        position: absolute;
        bottom: 5%;
      }

      .content-img {
        position: absolute;
        // border: 2px solid #73ad21;
        img {
          width: 100%;
        }
      }

      .content-container {
        position: absolute;
        bottom: 12%;
        // border: 1px solid #73ad21;
        width: 93%;
        height: 269px;
        background: #ffffff;
        box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
        border-radius: 0px 8px 8px 0px;
      }
    }
    .check-health {
      position: absolute;
      bottom: -2%;
      // border: 1px solid #73ad21;
      width: 100%;
      height: 70px;
      // box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
      left: 50%;
      transform: translate(-50%);

      .check-health-btn {
        position: absolute;
        padding: 0px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .link-with-arrow-red {
      position: absolute;
      bottom: -9%;
      // border: 1px solid #73ad21;
      width: 80%;
      height: 45px;
      left: 54%;
      transform: translate(-50%);
      .arrow-svg {
        display: flex;
        justify-content: end;
      }
    }
  }
}
// end

.persona-health {
  font-family: 'Noto Sans Thai', sans-serif;
  width: 100%;
  background: white;

  .title-image {
    width: 100%;
    margin-bottom: 40px;
  }

  .error-text {
    color: red;
  }

  .container {
    padding-left: 5%;
    h1.title {
      margin-bottom: 20px;

      .persona-title {
        font-weight: 600;
        color: black;
      }

      .health-title {
        color: red;
        font-weight: 200;
      }
    }

    p.description {
      margin-bottom: 32px;
      font-style: normal;
      font-weight: 400;
      color: black;
    }

    .form-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      color: black;
    }

    .form-group-box {
      margin-bottom: 35px;
    }

    .gender-form-group {
      padding-left: 25px;
      margin-bottom: 10px;
      label {
        margin-left: 10px;
        color: black;
        font-weight: 600;
      }
    }

    .col-keyword-card {
      padding: 7px 15px;
    }

    .keyword-card {
      cursor: pointer;
      border-radius: 10px;
      .card-body {
        background-color: white;
        color: black;
        box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        .card-title {
          text-align: center;
          padding: 0px;
        }
      }
    }

    .selected-keyword-card {
      border: solid red 3px;
    }

    .submit-btn {
      background-color: red;
      padding: 12px;
      border-radius: 100px;
      width: 100%;
    }

    .img-choice {
      width: 100%;
    }
  }
}

.vc-switch-index-table-only {
  display: block;
  position: fixed;
  left: 0.76923077em !important;
  bottom: 0.76923077em;
  color: #fff;
  background-color: var(--VC-BRAND);
  line-height: 1;
  font-size: 1.07692308em;
  padding: 0.61538462em 1.23076923em;
  z-index: 10000;
  border-radius: 0.30769231em;
  box-shadow: 0 0 0.61538462em rgba(0, 0, 0, 0.4);
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.custom-header-form-ttb-the-teasure {
  padding: 0px 0 25px 0px;
  background-color: #f7f7f7;
  h1,
  .policy-no {
    font-size: 24px !important;
    font-weight: bold !important;
    margin: 15px 0 5px;
  }

  span {
    font-size: 16px;
  }
}

.over-text {
  inline-size: 180px;
  overflow-wrap: break-word;
}

.setting-consent {
  .card {
    border-radius: 20px;

    .card-body {
      background-color: initial;

      .subtitle {
        text-indent: 50px;
      }

      .consent {
        padding-bottom: 20px;
        .option {
          input[type="radio"] {
            box-shadow: 0px 1px 4px 1px #88888d;
            -webkit-appearance: inherit;
            border-radius: 999px;
            border: 1px solid #999999;
          }

          input[type="radio"]:checked:before {
            content: "";
            position: absolute;
            border-radius: 999px;
            left: 17%;
            top: 3px;
            width: 67%;
            height: 63%;
            background: #ed1b2e;
            border: 1px solid #ed1b2e;
          }
          .text-option {
            color: black;
            font-weight: bold;
            padding-left: 10px;
            margin-bottom: 0px;
            margin-top: 0.15rem;
          }
        }
      }
    }
  }
}

.consent-new-notice {
  .wrap-dm {
    align-items: flex-start;
    margin-top: 10px;

    input[type="checkbox"] {
      width: 60px;
      height: 40px;
      margin-top: -5px;
      margin-right: 1.25rem;
      accent-color: #ed262e;
    }
  }
}

.detail-claim-ref {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  // overflow: hidden;

  p {
    font-size: 13px;
    font-weight: normal;
    color: #000;
    margin: 0;
    max-width: 50%;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      font-size: 13px;
      font-weight: normal;
      color: #000;
      font-weight: bold;
      text-align: right;
    }
  }
}

.document-download-page {
  text-align: center;
  padding-top: 15px;

  * {
    // color: black;

    &:focus {
      outline: none !important;
    }
  }

  .btn-primary {
    color: #fff;
    border-radius: 30px;
    padding: 5px 20px;
  }

  h1 {
    font-size: 24px;
    color: #000000;
  }

  h2 {
    font-size: 20px;
  }

  .policy-checkbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    .checkbox-wrap {
      .checkbox {
        padding-left: 25px;
        .checkmark {
          top: 3px;
          border-radius: 50%;
          border-color: #8f8f8f;

          &::after {
            border-width: 0 2px 2px 0;
          }
        }

        input:checked ~ .checkmark {
          border-color: #da3739;
        }
      }
    }
  }

  .policy-list {
    margin-bottom: 20px;

    &.policy-doc {
      h1 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 5px;
        }
      }

      .no-policy {
        // margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60vh;
        text-align: center;

        h2 {
          font-size: 15px;
          font-weight: bold;
          color: #616161;
          line-height: 1.6;
        }

        p {
          font-size: 14px;
          color: #616161;
          line-height: 1.6;
        }

        a {
          font-size: 14px;
          color: #e51b2d;
          text-decoration: underline;
        }
      }

      .about-wrap {
        text-align: left;
        background: #ffffff;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 15px;

        h2 {
          font-size: 16px;
          font-weight: bold;
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        span {
          font-size: 15px;
          color: #616161;

          a {
            font-size: 14px;
            color: #e51b2d;
            text-decoration: underline;
          }
        }

        ol {
          margin: 0;
          margin-bottom: 10px;
          padding: 0 13px;
          font-size: 14px;
        }
      }

      .about-note {
        p {
          font-size: 13px;
          color: #000;
          margin-bottom: 5px;

          &:last-child {
            font-size: 12px;
            color: #616161;
          }
        }
      }

      .section-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          font-size: 14px;
          color: #616161;

          &:first-child {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            margin-right: 10px;
          }
        }
      }

      .policy-item-doc {
        position: relative;
        justify-content: flex-start;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);

        // border: 2px solid #cbcbcb;
        height: 180px;

        &.no-edit {
          height: auto;

          .policy-status {
            margin-top: 10px;

            a {
              color: #fff;
              text-decoration: underline;
            }
          }
        }

        img.next-up-down {
          position: absolute;
          top: 20px;
          right: 15px;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
        img.next-right {
          position: absolute;
          top: 20px;
          right: 15px;
          cursor: pointer;
          width: 16px;
          height: 16px;
        }

        img.icon_doc {
          top: 14px;
          left: 5px;
          cursor: pointer;
          width: 14px;
          height: 24px;
          margin-right: 10px;
          padding-bottom: 2px;
        }

        p {
          font-size: 16px;
        }

        .item-50 {
          display: flex;
          width: 100%;
          margin-bottom: 5px;

          &:first-child {
            margin-top: 3px;
          }

          p {
            flex: 1;
            color: #000;

            &:last-child {
              flex: none;
              width: 150px;
            }
          }
        }
      }
      .policy-item-doc-detail {
        position: relative;
        justify-content: flex-start;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);

        // border: 2px solid #cbcbcb;
        height: 180px;

        &.no-edit {
          height: auto;

          .policy-status {
            margin-top: 10px;

            a {
              color: #fff;
              text-decoration: underline;
            }
          }
        }

        img.next-up-down {
          position: absolute;
          top: 20px;
          right: 15px;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
        img.next-right {
          position: absolute;
          top: 20px;
          right: 15px;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }

        img.icon_doc {
          top: 14px;
          left: 5px;
          cursor: pointer;
          width: 14px;
          height: 24px;
          margin-right: 10px;
          padding-bottom: 2px;
        }

        p {
          font-size: 16px;
        }

        .item-50 {
          display: flex;
          width: 100%;
          margin-bottom: 5px;

          &:first-child {
            margin-top: 3px;
          }

          p {
            flex: 1;
            color: #000;

            &:last-child {
              flex: none;
              width: 150px;
            }
          }
        }
      }

      .note {
        text-align: left;
        p {
          font-size: 13px;
          color: #8f8f8f;
          margin: 0;
        }
      }
    }

    &.policy-doc-all {
      padding-top: 5px;

      .tab-fixed {
        position: fixed;
        top: 59px;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 10px;
        box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
        z-index: 10;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow-y: auto;

        // &::-webkit-scrollbar {
        //     width: 0px;
        //     background: transparent;
        // }

        .tab {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          background-color: #fff;
          border: 1px solid #e51b2d;
          border-radius: 6px;
          padding: 4px 7px;
          margin-right: 10px;
          white-space: nowrap;

          transition: all 0.3s;

          img {
            height: 20px;
            margin-right: 4px;
          }

          span {
            font-size: 15px;
            color: #e51b2d;
          }

          &:last-child {
            margin-right: 0;
          }

          &.active {
            background-color: #e51b2d;

            img {
              filter: brightness(0) invert(1);
            }

            span {
              color: #fff;
            }
          }
        }
      }

      .policy-item-doc {
        height: auto;

        &.disabled {
          div {
            opacity: 0.5;
          }
          .status {
            opacity: 1;
          }
        }

        h2 {
          padding-left: 5px;
          padding-right: 15px;
          font-size: large;
          color: black;
          font-weight: normal;
          img {
            margin-right: 5px;
            width: 14px;
          }
        }

        p {
          font-size: 14px;
          color: #000;
          line-height: 1.7;
        }

        .item-detail {
          p {
            font-size: 15px;
            font-weight: bold;
          }
        }

        .item-tags {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
          grid-gap: 5px;
          .item-status {
            margin-bottom: 5px;
            span {
              font-size: 15px;
              color: #fff;
              padding: 4px 8px;
              border-radius: 4px;
              min-width: none;
              &.active {
                background-color: #4fcb90;
              }
              &.inactive {
                background-color: #f92c2b;
              }

              &.white {
                border: 1px solid #f92c2b;
                color: #f92c2b;
              }
            }

            .item-50 {
              display: flex;
              width: 100%;
              margin-bottom: 5px;

              &:first-child {
                margin-top: 3px;
              }

              p {
                flex: 1;
                color: #000;

                &:last-child {
                  flex: none;
                  width: 150px;
                }
              }
            }
          }
        }

        .item-epolicy {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-top: 1px solid #cbcbcb;
          margin-top: 10px;
          padding-top: 10px;

          img {
            width: 13px;
          }

          span {
            font-size: 13px;
            color: #ed1b2e;
            margin: 0 5px;
            white-space: nowrap;
          }

          small i {
            font-size: 11px;
            color: #6c747a;
            white-space: nowrap;
          }
        }
      }
      .policy-item-doc-detail {
        height: auto;

        &.disabled {
          div {
            opacity: 0.5;
          }
          .status {
            opacity: 1;
          }
        }

        h2 {
          padding-left: 5px;
          padding-right: 15px;
          font-size: large;
          color: black;
          font-weight: normal;
          img {
            margin-right: 5px;
            width: 14px;
            padding-bottom: 5px;
          }
        }

        p {
          font-size: 14px;
          color: #000;
          line-height: 1.7;
        }

        .item-detail {
          p {
            font-size: 15px;
            font-weight: bold;
          }
        }

        .item-tags {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
          grid-gap: 5px;
          .item-status {
            margin-bottom: 5px;
            span {
              font-size: 15px;
              color: #fff;
              padding: 4px 8px;
              border-radius: 4px;
              min-width: none;
              &.active {
                background-color: #4fcb90;
              }
              &.inactive {
                background-color: #f92c2b;
              }

              &.white {
                border: 1px solid #f92c2b;
                color: #f92c2b;
              }
            }

            .item-50 {
              display: flex;
              width: 100%;
              margin-bottom: 5px;

              &:first-child {
                margin-top: 3px;
              }

              p {
                flex: 1;
                color: #000;

                &:last-child {
                  flex: none;
                  width: 150px;
                }
              }
            }
          }
        }

        .item-epolicy {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-top: 1px solid #cbcbcb;
          margin-top: 10px;
          padding-top: 10px;

          img {
            width: 13px;
          }

          span {
            font-size: 13px;
            color: #ed1b2e;
            margin: 0 5px;
            white-space: nowrap;
          }

          small i {
            font-size: 11px;
            color: #6c747a;
            white-space: nowrap;
          }
        }
      }

      .detail-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: -75px -15px 0 -15px;
        padding: 25px 0;
        background-color: #f7f7f7;

        .icon {
          width: 35px;
        }

        h1 {
          font-size: 24px;
          margin: 15px 0 5px;
        }

        span {
          font-size: 16px;
        }
      }

      .ecare-card {
        position: relative;
        margin-top: -15px;

        img {
          width: 95%;
          max-width: 700px;
          border-radius: 6px;
        }

        .ecare-content-title {
          position: absolute;
          top: 26%;
          left: 6%;
          display: flex;
          flex-direction: row;
          margin-left: -7%;
          justify-content: center;
          align-items: center;
          width: 90%;

          * {
            // font-weight: bold;
            font-size: 9px;
            color: #616161;
          }
        }
        .ecare-content {
          position: absolute;
          top: 44%;
          left: 6%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          width: 90%;

          * {
            font-size: 8px;
            color: #616161;
          }

          .ecare-col {
            width: 49%;

            &:last-child {
              flex: 1;
            }

            // .ecare-item {
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: flex-start;
            //     align-items: flex-start;
            //     margin-bottom: 5px;

            //     span {
            //         white-space: nowrap;

            //         &:last-child {
            //             font-weight: bold;
            //             margin-left: 5px;
            //         }
            //     }
            // }
            .ecare-item-ha-type {
              display: flex;
              flex-direction: row;
              margin-left: 6%;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 5px;

              span {
                white-space: nowrap;

                &:last-child {
                  // font-weight: bold;
                  margin-left: 5px;
                }
              }
            }
            .ecare-item-hs-type {
              display: flex;
              flex-direction: row;
              // margin-left: 15%;
              // margin-left: 6%;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 10px;

              span {
                white-space: nowrap;

                &:last-child {
                  // font-weight: bold;
                  margin-left: 5px;
                }
              }
            }
            .ecare-item-pa-type {
              display: flex;
              flex-direction: row;
              // margin-left: 15%;
              justify-content: flex-start;
              align-items: flex-start;
              margin-bottom: 15px;

              span {
                white-space: nowrap;

                &:last-child {
                  // font-weight: bold;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      .detail-withdraw {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0;

        .withdraw {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: #e51b2d;
          width: 100%;
          max-width: 360px;
          padding: 5px 10px;
          border-radius: 6px;
          margin-bottom: 10px;

          span {
            color: #fff;
          }

          img {
            width: 14px;
            filter: brightness(0) invert(1);
          }
        }
      }

      .detail-epolicy {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0;

        .download {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: #e51b2d;
          width: 100%;
          max-width: 360px;
          padding: 5px 10px;
          border-radius: 6px;
          margin-bottom: 10px;

          span {
            color: #fff;
          }

          img {
            width: 14px;
            filter: brightness(0) invert(1);
          }
        }

        small {
          i {
            color: #6c747a;
          }
        }
      }

      .detail-body {
        background-color: #fff;
        margin: 0 -15px;

        .tab-fix-top {
          position: fixed;
          top: 47px;
          left: 0;
          width: 100%;
          z-index: 10;
        }

        .detail-tabs {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          overflow-y: auto;
          padding-bottom: 10px;

          // &::-webkit-scrollbar {
          //     width: 0px;
          //     background: transparent;
          // }

          .tab {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid #e8e8e8;
            padding: 10px;
            white-space: nowrap;
            transition: all 0.3s;

            img {
              height: 20px;
              margin-right: 4px;
              filter: brightness(0);
            }

            span {
              font-size: 15px;
              color: #000;
            }

            .tab-border {
              opacity: 0;
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 5px;
              border-radius: 20px;
              background-color: #e51b2d;
              transition: all 0.3s;
            }

            &:last-child {
              margin-right: 0;
            }

            &.active {
              img {
                filter: unset;
              }

              span {
                color: #e51b2d;
              }

              .tab-border {
                opacity: 1;
              }
            }
          }
        }

        .detail-content {
          margin-top: -10px;

          .detail-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px;
            // overflow: hidden;

            p {
              font-size: 15px;
              font-weight: bold;
              color: #000;
              margin: 0;
              max-width: 50%;

              &:first-child {
                text-align: left;
              }

              &:last-child {
                font-weight: normal;
                color: #616161;
                text-align: right;
              }
            }
          }

          .detail-coverage {
            border: 1px solid #e8e8e8;
            border-radius: 12px;
            margin: 10px 10px 0;
            overflow: hidden;

            .detail-row {
              p {
                max-width: 52%;
              }

              &:first-child {
                background-color: #fff6f8;
              }
            }
          }
        }
      }
    }

    .section-title {
      background-color: #fff;
      border: 1px solid #cbcbcb;
      border-left: none;
      border-right: none;
      margin: 0 -15px;
      margin-bottom: 15px;
      padding: 5px 15px;
      text-align: left;
    }

    .policy-item-doc {
      margin-top: 0;
      height: 190px;
    }
    .policy-item-doc-detail {
      margin-top: 0;
      height: 190px;
    }
  }

  .btn-full {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #e51b2d;
    border-radius: 6px;
    font-size: 15px;
    white-space: nowrap;
    padding: 5px 10px;
    margin-bottom: 15px;
    cursor: pointer;

    span {
      color: #fff;
    }

    img {
      height: 13px;
      margin-left: 10px;
    }
  }

  .policy-item-doc {
    background-color: #fff;
    padding: 13px 20px 8px 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0px 10px 10px 0px #0000000D;
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      border-color: #de0000;
    }

    &.no-edit {
      overflow: hidden;
      cursor: no-drop;

      .policy-status {
        font-size: 12px;
        background-color: #616161;
        color: #fff;
        padding: 5px 25px;
        margin: 0 -24px 10px;
      }
    }

    &.policy-pending {
      height: auto !important;
    }

    h6 {
      font-weight: bold;
      // margin: 0;
      // margin-bottom: 5px;
      color: #0d0d0d;

      // span {
      //   color: #de0000;
      // }
    }

    h2 {
      font-weight: bold;
      margin: 0;
      margin-bottom: 5px;

      span {
        color: #de0000;
      }
    }

    p {
      font-size: 14px;
      color: #7b7b7b;
      margin: 0;
    }
  }
  .policy-item-doc-detail {
    background-color: #fff;
    padding: 20px 20px 15px 20px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0px 10px 10px 0px #0000000D;
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      border-color: #de0000;
    }

    &.no-edit {
      overflow: hidden;
      cursor: no-drop;

      .policy-status {
        font-size: 12px;
        background-color: #616161;
        color: #fff;
        padding: 5px 25px;
        margin: 0 -24px 10px;
      }
    }

    &.policy-pending {
      height: auto !important;
    }

    h6 {
      font-weight: bold;
      // margin: 0;
      // margin-bottom: 5px;
      color: #0d0d0d;

      // span {
      //   color: #de0000;
      // }
    }

    h2 {
      font-weight: bold;
      margin: 0;
      margin-bottom: 5px;

      span {
        color: #de0000;
      }
    }

    p {
      font-size: 14px;
      color: #7b7b7b;
      margin: 0;
    }
  }

  .payment-complete-title {
    font-size: 20px;
    font-weight: bold;
  }

  .modal-documents {
    margin-top: 60px;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
    }

    .modal-body {
      text-align: left;
      overflow: hidden;
      padding-top: 20px;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .mt-5 {
        margin-top: 5rem;

        h4 {
          margin-bottom: 10px;
        }

        .form-group {
          margin-bottom: 10px;

          label {
            display: flex;
            align-items: center;

            input {
              margin-left: 10px;
            }
          }
        }
      }

      .btn-danger {
        width: 100%;
        border: none;
        margin-top: 20px;
        border-radius: 30px;
      }
    }
  }
}

.modal-documents {
  margin-top: 60px;
  color: black;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  .modal-body {
    text-align: left;
    overflow: hidden;
    padding-top: 20px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .mt-5 {
      margin-top: 5rem;

      h4 {
        margin-bottom: 10px;
      }

      .form-group {
        margin-bottom: 10px;

        label {
          // display: flex;
          align-items: center;

          input {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }

    .btn-danger {
      width: 100%;
      border: none;
      margin-top: 20px;
      border-radius: 30px;
    }
  }
}
