.wrapper-custom-prefix {
    position: relative;
}

.icon-custom-prefix {
    height: 1.5rem;
    width: 1.5rem;
    // background-color: red;
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    z-index: 2;
    color: #dbdbdb;
}

.input-custom-prefix {
    height: 50px;
    box-sizing: border-box;
    padding-left: 1.5rem;
}

.broker-table {
    background: none !important;
}

.broker-table th,
.broker-table td {
    border: none !important;
    color: #68737a !important;
}

.broker-table tbody tr:nth-child(odd) {
    background: #fff;
}

.custom-from-react-table .-pagination {
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 3px;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.custom-from-react-table .-pagination input,
.custom-from-react-table .-pagination select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
}

.custom-from-react-table .-pagination .-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    cursor: pointer;
    outline: none;
}

.custom-from-react-table .-pagination .-btn[disabled] {
    opacity: 0.5;
    cursor: default;
}

.custom-from-react-table .-pagination .-btn:not([disabled]):hover {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
}

.custom-from-react-table .-pagination .-previous,
.custom-from-react-table .-pagination .-next {
    -ms-flex: 1;
    flex: 1 1;
    text-align: center;
}

.custom-from-react-table .-pagination .-center {
    -ms-flex: 1.5;
    flex: 1.5 1;
    text-align: center;
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.custom-from-react-table .-pagination .-pageInfo {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
}

.custom-from-react-table .-pagination .-pageJump {
    display: inline-block;
}

.custom-from-react-table .-pagination .-pageJump input {
    width: 70px;
    text-align: center;
}

.custom-from-react-table .-pagination .-pageSizeOptions {
    margin: 3px 10px;
}

.custom-from-react-table .MuiTableCell-root {
    padding: 8px;
    /* line-height: 0.8rem; */
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-from-react-table .MuiTableHead-root {
    // -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15) !important;
    // box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15) !important;
}
