.otp-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 0px;

    .seperate-btn {
        position: relative;

        hr {
            margin: 10px -35px;
        }
    }

    .btn {
        min-width: 220px;

        &:focus {
            box-shadow: none;
        }
    }

    #request-otp {
        padding-left: 5px;
        min-width: 100px;
        text-decoration: none;
    }

    .otp-wrap {
        flex-grow: 1;
        width: 100%;
        margin-bottom: 20px;

        .pincode-input-container {
            display: flex;
            position: relative;

            .pincode-input-text {
                flex: 1 1 0%;
                font-size: 38px;
                color: #495057;
                text-align: center;
                height: 50px;
                line-height: 1.7;
                margin: 0 10px;
                background-color: #fff;
                border: 1px solid #D3D3D3;
                border-radius: 4px;

                &.active {
                    position: relative;
                    border-color: #029CFF;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 9px;
                        left: 50%;
                        width: 1px;
                        height: 60%;
                        border-left: 1px solid #707070;
                        z-index: 4;
                        -moz-animation: myCursor 1s infinite;
                        -webkit-animation: myCursor 1s infinite;
                        -o-animation: myCursor 1s infinite;
                        animation: myCursor 1s infinite;
                    }

                    @-webkit-keyframes myCursor {
                        0% {opacity: 0;}
                        50% {opacity: 1;}
                        100% {opacity: 0;}
                    }
                      
                    @keyframes myCursor {
                        0% {opacity: 0;}
                        50% {opacity: 1;}
                        100% {opacity: 0;}
                    }
                } 
            }
        }

        .input-mask {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }   
}

// .mark-star {
//     display: inline-block;

//     span {
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: flex-end;

//         span {
//             font-size: 36px;
//             font-weight: 100;
//             line-height: 0;
//             margin-bottom: 4px;
//         }
//     }
// }

.grecaptcha-badge { 
    visibility: hidden;
}

@media screen and (max-width: 415px) {
    .otp-container .otp-wrap .pincode-input-container .pincode-input-text {
        margin: 0 5px;
    }
}