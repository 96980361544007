.modal-confirm {
    img {
        width: 100%;
        min-height: 200px;
        margin-top: 20px;
        margin-bottom: 40px;
        animation: scaling .5s ease-in-out .4s forwards;
    }

    p {
        margin-bottom: 40px;
    }

    @keyframes scaling {
        0% {
            transform: scale(1);
        }
        30% {
            transform: scale(0.1);
        }
        60% {
            transform: scale(1.1);
        }
        80% {
            transform: scale(0.9);
        }
        100% {
            transform: scale(1);
        }
    }
}