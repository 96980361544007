* {
    font-family: 'Noto Sans Thai', sans-serif;
}

.policy-list-contact-page {
    overflow: hidden;

    input[type='radio'],
    input[type='checkbox'] {
        box-sizing: border-box;
        padding: 0;
        transform: scale(1.8) !important;
    }

    .form-check-input {
        position: absolute;
        margin-top: 0.6rem;
        margin-left: -0.8rem;
    }
}

#cb-red {
    accent-color: red;
}

.tooltip-custom {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}

.tooltip-custom .tooltiptext {
    visibility: hidden;
    min-width: 100px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    font-size: 8px;
    padding: 5px;
}

.tooltip-custom .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 60%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.tooltip-custom:hover .tooltiptext {
    visibility: visible;
}
.contact-form {
    .title {
        font-weight: 400;
        font-size: 20px;
        padding-top: 26px;
        color: #000000;
        // text-align: center;
        // text-transform: capitalize;
    }
    .address-detail {
        margin-top: 20px;
    }
    .form-control {
        border-radius: 8px;
    }
    .number-policy {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        color: #ffffff;
    }
    .edit-backward {
        position: absolute;
        top: 50%;
        right: -10%;
        transform: translate(-50%, -50%);
        color: #0d0d0d;
    }
}

.rc-tooltip-inner {
    background: #1a1a11 !important;
}
