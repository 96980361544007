#header-main {
  width: 100%;
  position: fixed;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background-color: #fff;
  text-align: center;
  padding: 5px 15px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1);
  transition: top 0.4s;
  z-index: 10;

  img {
    width: 100%;
    max-width: 245px;
    max-height: 55px;
  }

  span {
    width: 25px;

    img {
      cursor: pointer;
    }
  }

  &.navbar--hidden {
    top: -50px;
  }
}

main {
  margin-top: 60px;
  padding: 15px 0;
}

footer {
  font-size: 12px;
  text-align: center;
  padding-bottom: 20px;
}
